import type * as A from '@/api';
import { logger } from '@/utils/logger';
import { graphql } from '@/utils/graphql';
import type { PartialBy } from './types';
import type Dataset from './dataset';

type FolderConstructorInput = A.GetFolderQuery['getFolder'];

type FolderCreateInput = PartialBy<FolderConstructorInput, 'id'>;

type FolderUpdateInput = Partial<FolderConstructorInput>;

export default class Folder {
  readonly id: string;

  readonly name: string;

  // parentId can be ID of the parent folder or the ID of the workspace when it's the root "Hub" folder
  readonly parentId: string;

  // parent can be undefined when the folder is the root "Hub" folder
  readonly parent?: Pick<Folder, 'id' | 'name'>;

  readonly subFolders: Pick<Folder, 'id' | 'name'>[];

  readonly datasets: Pick<Dataset, 'id' | 'name'>[];

  private constructor(input: FolderConstructorInput) {
    this.id = input.id;
    this.name = input.name;
    this.parentId = input.parentId;
    this.parent = input.parent;
    this.subFolders = input.subFolders ? input.subFolders.items : [];
    this.datasets = input.datasets ? input.datasets.items : [];

    this.update = this.update.bind(this);
  }

  async update(input: FolderUpdateInput): Promise<void> {
    logger.info({ label: 'app.services.folder.update.input', message: input });
    await graphql('updateFolder', { input: { id: this.id, ...input } });
    Object.entries(input).forEach(([k, v]) => {
      // @ts-ignore due to removing suppressImplicitAnyIndexErrors after upgrading typescript to 5.7.3
      this[k] = v;
    });
  }

  static async getFolderById(id: string): Promise<Folder> {
    logger.info(id, { label: 'app.services.folder.getFolderById.id' });
    const data = await graphql('getFolder', { id });
    return new Folder(data);
  }

  static async getFoldersByParentId(parentId: string): Promise<Folder[]> {
    logger.info(parentId, { label: 'app.services.folder.getFoldersByParentId.parentId' });
    const data = await graphql('listFoldersByParent', { parentId });
    return data.items.map((el) => new Folder(el));
  }

  static async getRootFolder(workspaceId: string): Promise<Folder> {
    logger.info(workspaceId, { label: 'app.services.folder.getRootFolder.workspaceId' });
    const rs = await Folder.getFoldersByParentId(workspaceId);
    logger.debug({ label: 'app.services.folder.getRootFolder.rs', message: rs });
    if (!rs || rs.length !== 1)
      throw new Error('Folder - getRootFolder - invalid condition - (!rs || rs.length !== 1)');
    return rs[0];
  }

  static async create(input: FolderCreateInput): Promise<string> {
    logger.info({ label: 'app.services.folder.create.input', message: input });
    const data = await graphql('createFolder', { input });
    return data.id;
  }

  static async delete(id: string): Promise<void> {
    logger.info(id, { label: 'app.services.folder.delete.id' });

    // make sure it's empty
    const folder = await Folder.getFolderById(id);
    const subFolderCount = folder.subFolders.length;
    const datasetCount = folder.datasets.length;

    logger.debug({ label: 'app.services.folder.delete.subFolderCount', message: subFolderCount });
    logger.debug({ label: 'app.services.folder.delete.datasetCount', message: datasetCount });

    if (subFolderCount > 0 || datasetCount > 0)
      // vinhf want to create a class for BeemError which always have "code" and "message" and "stack trace"
      throw new Error(
        `FOLDER_NOT_EMPTY - Folder is not empty: ${subFolderCount} sub-folders, ${datasetCount} datasets. Please empty before deleting it.`,
      );

    await graphql('deleteFolder', { input: { id } });
  }
}
