import type { Filter, Sorting } from '@/models/objects/datasetViewState';
import type { SqlQueryResults } from './types';
import type Dataset from './dataset';
import type DatasetTest from './datasetTest';
// import Workspace from './workspace';
import DataObject from './dataObject';
import { graphql, subscribe } from '@/utils/graphql';
// import { addBackendSuffix } from '@/utils/namingConvention';
import { logger } from '@/utils/logger';

const ns = 'app.services.dataset.common';

function removeComments(query: string): string {
  return query
    .split('\n')
    .filter((el) => !el.startsWith('--'))
    .join('\n');
}

export async function testSqlQuery(
  query: string,
  pageSize: number,
  startIndex: number,
  filters: Filter[] = [],
  sortings: Sorting[] = [],
): Promise<SqlQueryResults> {
  // const workspace = await Workspace.getWorkspaceById(workspaceId);
  // const orgId = workspace.organization.cloudResourceId;
  // const wsSeq = addBackendSuffix(String(workspace.seq));
  const sqlQuery = removeComments(query);

  const { executionId } = await graphql('beemAgentPreviewDatasetQuery', {
    query: {
      sqlQuery,
      pageSize,
      startIndex,
      filters: JSON.stringify(filters),
      sortings: JSON.stringify(sortings),
    },
  });
  logger.info({ label: `${ns}.testSqlQuery.executionId`, message: executionId });

  const MAX_TIME = 60;
  const start = Date.now();
  let i = 0;
  while (Date.now() - start < MAX_TIME * 60000) {
    // eslint-disable-next-line no-await-in-loop, @typescript-eslint/no-loop-func
    await new Promise((resolve) => setTimeout(resolve, Math.max(2000, i * 5000)));
    i += 1;

    // eslint-disable-next-line no-await-in-loop
    const { status, rows, error } = await graphql('beemAgentCheckRedshiftQuery', {
      query: { executionId },
    });
    if (rows) return JSON.parse(rows);
    if (status === 'FAILED') throw new Error(error);
    if (status === 'ABORTED')
      throw new Error(`The query was aborted. Please check query execution ID ${executionId}.`);
  }
  throw new Error(`The query exceeded the maximum of ${MAX_TIME} minutes.`);
}

export async function getContentStart(
  this: DataObject | Dataset | DatasetTest,
  pageSize: number,
  startIndex: number,
  filters: Filter[] = [],
  sortings: Sorting[] = [],
  onSuccess?: (executionId: string) => void,
): Promise<{ executionId: string }> {
  const command =
    this instanceof DataObject ? 'beemAgentGetDataObjectContent' : 'beemAgentGetDatasetContent';
  const data = await graphql(command, {
    query: {
      cloudResourceId: this.cloudResourceId,
      version: this instanceof DataObject ? '' : String(this.version), // should be a number but it doesn't hurt, we can refactor later for consistency
      pageSize,
      startIndex,
      filters: JSON.stringify(filters),
      sortings: JSON.stringify(sortings),
    },
  });
  if (onSuccess) onSuccess(data.executionId);
  return data;
}

export function listenToPipelineResult(
  this: Dataset | DatasetTest,
  onSuccess: () => void,
  onFailure: () => void,
): void {
  const { cloudResourceId } = this;
  subscribe('onCreatePipelineResult', {}, (result, done) => {
    if (result.recordCloudResourceId === cloudResourceId) {
      if (result.status === 'SUCCESS') onSuccess();
      else onFailure();
      done();
    }
  });
}

export async function waitForRedshiftQueryUntilDoneAndReturnResults(query: {
  executionId: string;
}): Promise<SqlQueryResults> {
  const MAX_TIME = Date.now() + 60 * 60 * 1000;
  let i = 0;
  while (Date.now() < MAX_TIME) {
    // eslint-disable-next-line no-await-in-loop, @typescript-eslint/no-loop-func
    await new Promise((resolve) => setTimeout(resolve, 1000 * Math.min(30, i)));
    i += 1;
    // eslint-disable-next-line no-await-in-loop
    const { status, rows, error } = await graphql('beemAgentCheckRedshiftQuery', { query });
    if (rows) return JSON.parse(rows);
    if (status === 'FAILED') throw new Error(error);
    if (status === 'ABORTED')
      throw new Error(`The query was aborted. Please check query ${query}.`);
  }
  throw new Error(`The query exceeded the maximum of ${MAX_TIME} minutes.`);
}

export async function cancelRedshiftQueryAndIgnoreError(query: {
  executionId: string;
}): Promise<void> {
  try {
    const data = await graphql('beemAgentCancelRedshiftQuery', { query });
    logger.debug({ label: 'cancelRedshiftQuery', message: data });
  } catch (e) {
    logger.debug({ label: 'cancelRedshiftQuery', message: 'errored' });
  }
}
