import type { Task } from 'redux-saga';
import DatasetViewState from '../objects/datasetViewState';
import type { Filter, Sorting } from '../objects/datasetViewState';
import type { Effects, ReduxSagaEffect } from '../types';
import type { SqlQueryResults } from '@/services/types';
import DataObject from '@/services/dataObject';
import Dataset from '@/services/dataset';
import DatasetTest from '@/services/datasetTest';
import {
  waitForRedshiftQueryUntilDoneAndReturnResults,
  cancelRedshiftQueryAndIgnoreError,
} from '@/services/dataset.common';
import { sgcall, sgselect } from '@/utils/reduxSaga';
import { handleUserError } from '@/utils/handleError';
import { assertIsDefined, assertIsNumber } from '@/utils/typeChecks';
import { graphql } from '@/utils/graphql';
import { logger } from '@/utils/logger';

const ns = 'app.models.common.getContent';

type GetContentCompatibleTypes = DataObject | Dataset | DatasetTest;

type GetContentEffectPayload<T extends GetContentCompatibleTypes> = {
  current: T;
  pageSize: number;
  currentPage?: number;
};

export type UpdateViewStateEffectPayload = {
  filters?: { index: number; value: Filter }[];
  sortings?: { index: number; value: Sorting }[];
};

type SaveRowCountAction = {
  type: 'saveRowCount';
  payload: { rowCount: number | null };
};

type SaveRecordsAction = {
  type: 'saveRecords';
  payload: { records: SqlQueryResults | null };
};

type SaveViewStateAction = {
  type: 'saveViewState';
  payload: { viewState: DatasetViewState | null };
};

const executionIdByRequestIdMap: Record<string, string> = {};
const getContentEffect: ReduxSagaEffect<{
  payload: GetContentEffectPayload<GetContentCompatibleTypes>;
}> = function* getContentEffect({ payload }, { put, cancelled, spawn }) {
  const requestId = Date.now().toString();
  const onSuccess = (executionId: string) => {
    executionIdByRequestIdMap[requestId] = executionId;
  };
  try {
    yield put<SaveRecordsAction>({ type: 'saveRecords', payload: { records: null } });
    const { current, pageSize, currentPage = 1 } = payload;
    if (!current) return;

    const { currentViewState, datasetId } = yield* sgselect((s) => {
      if (current instanceof DataObject)
        return {
          currentViewState: s.dataObjects.viewState,
          datasetId: s.dataObjects.current?.id ?? '',
        };
      if (current instanceof Dataset)
        return { currentViewState: s.dataset.viewState, datasetId: s.dataset.current?.id ?? '' };
      if (current instanceof DatasetTest)
        return {
          currentViewState: s.datasetTests.viewState,
          datasetId: s.datasetTests.current?.id ?? '',
        };
      throw new Error('BEEM240610095844');
    });

    let viewState = currentViewState;

    // if the viewState is null, try to get it from dynamoDB
    if (viewState === null) {
      const userId = yield* sgselect((s) => s.user.currentUser.id);

      let objType;

      if (current instanceof DataObject) objType = 'dataObjects';
      if (current instanceof Dataset) objType = 'dataset';
      if (current instanceof DatasetTest) objType = 'datasetTests';

      if (datasetId && userId) {
        const iDatasetUserId = `${objType}${datasetId}#${userId}`;
        const iDatasetUser = yield* sgcall(() =>
          graphql('getIDatasetUser', {
            id: iDatasetUserId,
          }),
        );
        if (iDatasetUser) {
          logger.debug({ label: `${ns}.viewState`, message: 'loading existing viewState...' });
          const options = JSON.parse(iDatasetUser.options);
          viewState = new DatasetViewState({ ...options.viewState, disable: true });
          yield put<SaveViewStateAction>({
            type: 'saveViewState',
            payload: {
              viewState,
            },
          });
        } else {
          logger.debug({ label: `${ns}.viewState`, message: 'no existing viewState found.' });
        }
      }
    }

    // logger.debug({ label: `${ns}.viewState`, message: JSON.stringify(viewState) });

    let filters = viewState?.filters;
    if (filters) {
      const { columns } = current;
      assertIsDefined(columns, 'BEEM241018215654');
      filters = viewState?.getFiltersToApply(columns);
    }
    const sortings = viewState?.sortings;

    let rowCount = yield* sgselect((s) => {
      if (current instanceof DataObject) return s.dataObjects.rowCount;
      if (current instanceof Dataset) return s.dataset.rowCount;
      if (current instanceof DatasetTest) return s.datasetTests.rowCount;
      throw new Error('BEEM240610095846');
    });
    // this condition ensures that rowCount is only requested once for the same set of filters
    // since it's only reset to null when applyFilters is called
    if (rowCount === null) {
      if (filters?.length) {
        const data1 = yield* sgcall(() => current.getContentStart(0, 0, filters, [], onSuccess));
        const [rs] = yield* sgcall(() => waitForRedshiftQueryUntilDoneAndReturnResults(data1));
        const temp = rs?.count;
        assertIsNumber(temp, 'BEEM240704211054');
        rowCount = temp;
      } else {
        assertIsNumber(current.rowCount, 'BEEM240704211055');
        rowCount = current.rowCount;
      }
    }
    yield put<SaveRowCountAction>({ type: 'saveRowCount', payload: { rowCount } });

    const data2 = yield* sgcall(() =>
      current.getContentStart(pageSize, (currentPage - 1) * pageSize, filters, sortings, onSuccess),
    );
    const records = yield* sgcall(() => waitForRedshiftQueryUntilDoneAndReturnResults(data2));
    yield put<SaveRecordsAction>({ type: 'saveRecords', payload: { records } });
  } catch (e) {
    handleUserError(e, `${ns}.getContent.error`);
    yield put<SaveRecordsAction>({ type: 'saveRecords', payload: { records: null } });
  } finally {
    if (yield cancelled()) {
      yield spawn(function* a() {
        while (!executionIdByRequestIdMap[requestId])
          yield* sgcall(() => new Promise((resolve) => setTimeout(resolve, 1000)));
        yield* sgcall(() =>
          cancelRedshiftQueryAndIgnoreError({
            executionId: executionIdByRequestIdMap[requestId],
          }),
        );
      });
    }
  }
};

let forked: Task;
function* gatekeeperEffect<T extends GetContentCompatibleTypes>(
  data: { payload: GetContentEffectPayload<T> },
  effects: Effects,
) {
  if (forked?.isRunning()) yield effects.cancel(forked);
  forked = (yield effects.fork(getContentEffect, data, effects)) as Task;
}

export function generateEffectsForGetContentCompatibleType<T extends GetContentCompatibleTypes>(
  reduxModelNamespace: 'dataObjects' | 'dataset' | 'datasetTests',
) {
  return {
    *updateViewState(
      { payload: { filters, sortings } }: { payload: UpdateViewStateEffectPayload },
      { put }: Effects,
    ) {
      const { viewState, datasetId } = yield* sgselect((s) => {
        if (reduxModelNamespace === 'dataObjects')
          return { viewState: s.dataObjects.viewState, datasetId: s.dataObjects.current?.id ?? '' };
        if (reduxModelNamespace === 'dataset')
          return { viewState: s.dataset.viewState, datasetId: s.dataset.current?.id ?? '' };
        if (reduxModelNamespace === 'datasetTests')
          return {
            viewState: s.datasetTests.viewState,
            datasetId: s.datasetTests.current?.id ?? '',
          };
        throw new Error('BEEM240610095845');
      });

      const userId = yield* sgselect((s) => s.user.currentUser.id);

      // filters
      let newFilters: Filter[] = [];
      if (filters !== undefined) {
        filters.forEach(({ value }) => {
          newFilters.push(value);
        });
      } else {
        newFilters = viewState?.filters ?? [];
      }

      // sortings
      let newSortings: Sorting[] = [];
      if (sortings !== undefined) {
        sortings.forEach(({ value }) => {
          newSortings.push(value);
        });
      } else {
        newSortings = viewState?.sortings ?? [];
      }

      const newViewState = new DatasetViewState({ filters: newFilters, sortings: newSortings });
      yield put<SaveViewStateAction>({
        type: 'saveViewState',
        payload: {
          viewState: newViewState,
        },
      });

      // persist view state to dynamoDB
      if (datasetId && userId) {
        const iDatasetUserId = `${reduxModelNamespace}${datasetId}#${userId}`;

        const iDatasetUser = yield* sgcall(() =>
          graphql('getIDatasetUser', {
            id: iDatasetUserId,
          }),
        );

        // existing config
        if (iDatasetUser) {
          const response = yield* sgcall(() =>
            graphql('updateIDatasetUser', {
              input: {
                id: iDatasetUserId,
                datasetId,
                userId,
                options: JSON.stringify({ viewState: newViewState }),
              },
            }),
          );
          logger.debug({
            label: 'updateViewState.updateIDatasetUser',
            message: `${response?.id} updated`,
          });
        }
        // new config
        else {
          const response = yield* sgcall(() =>
            graphql('createIDatasetUser', {
              input: {
                id: iDatasetUserId,
                datasetId,
                userId,
                options: JSON.stringify({ viewState: newViewState }),
              },
            }),
          );
          logger.debug({
            label: 'updateViewState.createIDatasetUser',
            message: `${response?.id} created`,
          });
        }
      }
    },
    *getContent(args: { payload: GetContentEffectPayload<T> }, effects: Effects) {
      yield effects.call(gatekeeperEffect, args, effects);
    },
    *applySorting(
      args: { payload: Omit<GetContentEffectPayload<T>, 'currentPage'> },
      effects: Effects,
    ) {
      yield effects.call(gatekeeperEffect, args, effects);
    },
    *applyFilters(
      args: { payload: Omit<GetContentEffectPayload<T>, 'currentPage'> },
      effects: Effects,
    ) {
      yield effects.put<SaveRowCountAction>({ type: 'saveRowCount', payload: { rowCount: null } });
      yield effects.call(gatekeeperEffect, args, effects);
    },
  };
}
