import type * as A from '@/api';
import type { Filter, Sorting } from '@/models/objects/datasetViewState';
import { logger } from '@/utils/logger';
import { graphql } from '@/utils/graphql';
import User from './user';
import DatasetTest from './datasetTest';

export type SupportedDownloadRecordTypes =
  | 'dataObject'
  | 'dataset'
  | 'datasetTest'
  | 'datasetAllTests';

export type SupportedDownloadFileTypes = 'csv' | 'xlsx';

type DownloadConstructorInput = A.GetDownloadQuery['getDownload'];

export default class Download {
  readonly id: string;

  readonly dataId: string;

  readonly dataTimeStamp: number;

  readonly requester: User;

  readonly requestTimeStamp: number;

  readonly signedDownloadUrl: string;

  private constructor(input: DownloadConstructorInput) {
    this.id = input.id;
    this.dataId = input.dataId;
    this.dataTimeStamp = input.dataTimeStamp;
    this.requester = new User(input.requester);
    this.requestTimeStamp = input.requestTimeStamp;
    this.signedDownloadUrl = input.signedDownloadUrl;
  }

  static async getDownloadsByDataId(dataId: string): Promise<Download[]> {
    logger.info({ label: 'app.model.download.getDownloadsByDataId.dataId', message: dataId });
    const data = await graphql('listDownloadsByData', { dataId });
    return data.items.map((el) => new Download(el));
  }

  static async invokeDownload(
    dataType: SupportedDownloadRecordTypes,
    dataId: string,
    dataName: string,
    dataCloudResourceId: string,
    fileType: SupportedDownloadFileTypes = 'csv',
    filters: Filter[] = [],
    sortings: Sorting[] = [],
  ): Promise<Download> {
    logger.info(dataType, { label: 'app.model.download.invokeDownload.dataType' });
    logger.info(dataId, { label: 'app.model.download.invokeDownload.dataId' });
    logger.info(dataCloudResourceId, {
      label: 'app.model.download.invokeDownload.dataCloudResourceId',
    });
    let lookup: Record<string, string> | undefined;
    if (dataType === 'datasetAllTests') {
      const tests = await DatasetTest.getDatasetTestsByDataset(dataId);
      lookup = tests.reduce((acc, cur) => {
        acc[cur.id] = cur.name;
        return acc;
      }, {} as Record<string, string>);
    }
    const data = await graphql('beemAgentInvokeDownload', {
      input: {
        dataType,
        dataId,
        dataName,
        dataCloudResourceId,
        fileType: `${fileType}${
          lookup ? `&map_test_uuid_to_name=${encodeURIComponent(JSON.stringify(lookup))}` : ``
        }`,
        filters: JSON.stringify(filters),
        sortings: JSON.stringify(sortings),
      },
    });
    return new Download(data);
  }
}
