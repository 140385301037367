import flatten from 'flat';
import dataset from './en-US/dataset';
import hub from './en-US/hub';
import folder from './en-US/folder';
import header from './en-US/header';
import datasetTest from './en-US/datasetTest';
import datasetAllTests from './en-US/datasetAllTests';
import visualization from './en-US/visualization';

export default flatten({
  ...dataset,
  ...hub,
  ...folder,
  ...header,
  ...datasetTest,
  ...datasetAllTests,
  ...visualization,
});
