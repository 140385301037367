import { parse } from 'querystring';
import { pathToRegexp } from 'path-to-regexp';
import React from 'react';
import { history } from 'umi';
import Skeleton from '../components/Skeleton';
import { logger } from './logger';

export const LOGIN_REDIRECT = 'beem-login-redirect-url';

export const getPageQuery = () => parse(window.location.href.split('?')[1]);

export function redirectToURLFromLocalStorage() {
  let redirect = localStorage.getItem(LOGIN_REDIRECT);
  if (redirect) {
    localStorage.removeItem(LOGIN_REDIRECT);
    const currentURL = new URL(window.location.href);
    const redirectURL = new URL(redirect, currentURL.origin);
    if (redirectURL.origin === currentURL.origin) {
      redirect = redirectURL.pathname + redirectURL.search + redirectURL.hash;
    } else {
      redirect = '/';
    }
  }
  history.push(redirect || '/');
}

/**
 * props.route.routes
 * @param router [{}]
 * @param pathname string
 */
export const getAuthorityFromRouter = (router = [], pathname) => {
  const authority = router.find(({ path }) => path && pathToRegexp(path).exec(pathname));
  if (authority) return authority;
  return undefined;
};

export const getRouteAuthority = (path, routeData) => {
  logger.info({ label: 'got a router', message: { path, routeData } });

  let authorities;

  routeData.forEach((route) => {
    // match prefix
    if (pathToRegexp(`${route.path}/(.*)`).test(`${path}/`)) {
      if (route.authority) {
        authorities = route.authority;
      } // exact match

      if (route.path === path) {
        authorities = route.authority || authorities;
      } // get children authority recursively

      if (route.routes) {
        authorities = getRouteAuthority(path, route.routes) || authorities;
      }
    }
  });

  return authorities;
};

export const startDownload = (url) => {
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('target', '_blank');
  element.setAttribute('rel', 'noopener noreferrer');
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

/**
 * Display Skeleton if value is undefined
 * @param {*} v the value to check
 * @param {*} fn the transform function, if null then just return the value itself
 * @param {*} style the style of the skeleton, must set width
 */
export const addLoadingSkelelon = (v, fn, style) => {
  return v !== undefined
    ? (fn && fn(v)) || v
    : React.createElement(Skeleton, {
        style,
      });
};

export const getGlobalCssProperty = (propertyName) =>
  parseInt(getComputedStyle(document.documentElement).getPropertyValue(propertyName), 10);
