export default {
  "tableContentView": {
    "sorting_popover": {
      "apply_button": "Apply Sorting",
      "clear_button": "Clear"
    },
    "filter_popover": {
      "apply_button": "Apply Filter",
      "clear_all_filters": "Clear All Filters",
      "new_filter": "New filter",
      "empty": "Empty"
    },
    "no_results": {
      "title": "Sometimes, nothing is a good thing!",
      "detail": "The dataset ran as expected, but simply did not produce any results."
    },
    "no_filtered_results": {
      "title": "Nothing to see here...",
      "detail": "Your current filters produced no results.",
      "action": "Try updating or removing your filters."
    }
  }
};
