import type * as A from '@/api';
import type { TriggerDetails } from './triggerDetails';
import Dataset from './dataset';
import Connection from './connection';
import TriggerDetailsService from './triggerDetails';
import { Trigger } from '@/api';
import { getCurrentOrganizationTimezone } from '@/utils/namingConvention';
import { graphql } from '@/utils/graphql';
import { logger } from '@/utils/logger';
import { assertIsDefined } from '@/utils/typeChecks';

type FlowConstructorInput = A.GetFlowQuery['getFlow'] & { beemAgentInfo?: string };

type FlowUpdateInput = Partial<Pick<FlowConstructorInput, 'enabled' | 'trigger' | 'datasetId'>> & {
  triggerDetails?: TriggerDetails;
  // parentCommitId?: string;
  // filePath?: string;
  // fileContent?: string;
  // commitMessage?: string;
};

// const COMMIT_SPECIFIER = 'main';

export const FILE_PATH = '/index.py';

// @ts-ignore
const useFakeInfo = !!globalThis.Cypress;

export default class Flow {
  readonly id: string;

  readonly name: string;

  readonly enabled: boolean;

  readonly trigger: A.Trigger;

  readonly triggerDetails: TriggerDetails;

  readonly workspaceId: string;

  readonly createdAt: Date;

  readonly datasetId: string;

  readonly datasetName: string;

  readonly destinationId: string;

  readonly destinationIntegrationName: string;

  readonly cloudResourceId: string;

  #stats?: {
    lastRun: Date;
    lastRunState: A.FlowRunStatus;
  };

  get lastRun(): Date | undefined {
    return this.#stats?.lastRun;
  }

  get lastRunState(): A.FlowRunStatus | undefined {
    return this.#stats?.lastRunState;
  }

  get state(): 'live' | 'paused' | 'null' {
    if (this.trigger === Trigger.MANUAL) return 'null';
    return this.enabled ? 'live' : 'paused';
  }

  private constructor(input: FlowConstructorInput) {
    this.id = input.id;
    this.name = input.name;
    this.enabled = input.enabled;
    this.trigger = input.trigger;
    this.triggerDetails = TriggerDetailsService.setDefaults(
      input.triggerDetails ? JSON.parse(input.triggerDetails) : {},
    );
    this.workspaceId = input.workspaceId;
    this.createdAt = new Date(input.createdAt);
    this.datasetId = input.datasetId;
    this.datasetName = input.dataset.name;
    this.destinationId = input.destinationId;
    this.destinationIntegrationName = input.destination.integration?.name ?? ''; // this is ok for now because all flows uses native integrations, not fivetran
    this.cloudResourceId = input.cloudResourceId;

    const cloudResourceInfo = input.cloudResourceInfo ?? input.beemAgentInfo;
    if (cloudResourceInfo) {
      const temp = JSON.parse(cloudResourceInfo);
      this.#stats = {
        lastRun: new Date(temp.lastRun),
        lastRunState: temp.lastRunState,
      };
    }

    this.getDataset = this.getDataset.bind(this);
    this.getDestination = this.getDestination.bind(this);
    // this.getCode = this.getCode.bind(this);
    this.update = this.update.bind(this);
  }

  async getDataset(): Promise<Dataset> {
    return Dataset.getDatasetById(this.datasetId);
  }

  async getDestination(): Promise<Connection> {
    return Connection.getConnectionById(this.destinationId);
  }

  // async getCode(): Promise<A.GetFlowCodeResponse> {
  //   try {
  //     return await graphql('getFlowCode', {
  //       query: {
  //         cloudResourceId: this.cloudResourceId,
  //         commitSpecifier: COMMIT_SPECIFIER,
  //         filePath: FILE_PATH,
  //       },
  //     });
  //   } catch (e) {
  //     // this is only until we have code repo for all the flows in prod
  //     return { commitId: 'no_code_repo', fileContent: '' };
  //   }
  // }

  async update(input: FlowUpdateInput): Promise<void> {
    logger.info({ label: 'app.services.flow.update.input', message: input });
    const {
      enabled,
      trigger,
      triggerDetails,
      datasetId,
      // parentCommitId,
      // filePath,
      // fileContent,
      // commitMessage,
    } = input;

    const newValuesToSet = {
      enabled,
      trigger,
      triggerDetails: triggerDetails ? JSON.stringify({ ...triggerDetails }) : undefined,
      datasetId,
    };
    await graphql('updateFlow', {
      input: { ...newValuesToSet, id: this.id },
    });
    Object.entries(newValuesToSet).forEach(([k, v]) => {
      // @ts-ignore due to removing suppressImplicitAnyIndexErrors after upgrading typescript to 5.7.3
      this[k] = v;
    });

    if (datasetId === undefined && trigger === undefined) return;

    let datasetNameEnvVar: string | undefined;
    if (datasetId) {
      const dataset = await Dataset.getDatasetById(datasetId);
      datasetNameEnvVar = dataset.cloudResourceId.split('.').pop();
      assertIsDefined(datasetNameEnvVar, 'BEEM220217134536');
      datasetNameEnvVar = `mv_tbl__mv_beem_app_${datasetNameEnvVar}`;
    }

    let cron: string | undefined;
    let timezone: string = getCurrentOrganizationTimezone();
    let enabled2: boolean | undefined;
    if (trigger) {
      assertIsDefined(triggerDetails, 'BEEM230218163946');
      assertIsDefined(enabled, 'BEEM230218163947');
      if (trigger === Trigger.MANUAL) {
        cron = '0 0 1 1 ? *';
        enabled2 = false;
      } else if (trigger === Trigger.SCHEDULED) {
        cron = triggerDetails.cron;
        timezone = triggerDetails.timezone;
        enabled2 = enabled;
      } else if (trigger === Trigger.CRON) {
        cron = triggerDetails.cron;
        timezone = triggerDetails.timezone;
        enabled2 = enabled;
      }
    }

    await graphql('modifyFlow', {
      input: {
        cloudResourceId: this.cloudResourceId,
        datasetNameEnvVar,
        cron,
        timezone,
        enabled: enabled2,
        // branchName: parentCommitId && COMMIT_SPECIFIER,
        // parentCommitId,
        // filePath,
        // fileContent,
        // commitMessage,
      },
    });
  }

  static async getFlowById(id: string): Promise<Flow> {
    logger.info(id, { label: 'app.services.flow.getFlowById.id' });
    const data = await graphql('getFlow', { id });
    let beemAgentInfo: string | undefined;
    if (!useFakeInfo) {
      ({ info: beemAgentInfo } = await graphql('beemAgentGetFlowInfo', {
        query: { cloudResourceId: data.cloudResourceId },
      }));
    }
    return new Flow({ ...data, beemAgentInfo });
  }

  static async getFlowsByWorkspace(workspaceId: string, remote = true): Promise<Flow[]> {
    let { items } = await graphql('listFlowsByWorkspace', { workspaceId });
    if (!useFakeInfo && remote) {
      items = await Promise.all(
        items.map(async (el) => {
          const { info: beemAgentInfo } = await graphql('beemAgentGetFlowInfo', {
            query: { cloudResourceId: el.cloudResourceId },
          });
          return { ...el, beemAgentInfo };
        }),
      );
    }
    return items.map((el) => new Flow(el));
  }
}
