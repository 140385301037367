import type * as A from '@/api';
import type { CognitoUser } from '@aws-amplify/auth';
import type Organization from './organization';
import { Auth } from '@/aws-config';
import Workspace from './workspace';
import { graphql } from '@/utils/graphql';
import { capitalize } from '@/utils/text';
import { addBackendSuffix, getCognitoUsernameFromAuth0UserId } from '@/utils/namingConvention';
import { logger } from '@/utils/logger';
import { handleUserError } from '@/utils/handleError';
import { assertIsDefined } from '@/utils/typeChecks';

const ns = 'app.services.user';

type UserConstructorInput = A.GetUserQuery['getUser'];

export default class User {
  readonly id: string;

  readonly email: string;

  readonly firstName?: string;

  readonly lastName?: string;

  readonly workspaces?: Partial<A.Workspace>[];

  readonly organizationsUser?: Partial<A.OrganizationUser>[];

  get name(): string {
    return `${capitalize(this.firstName)} ${capitalize(this.lastName)}`;
  }

  // eslint-disable-next-line class-methods-use-this
  get avatar(): string {
    return ''; // to be implemented
  }

  constructor(input: UserConstructorInput) {
    this.id = input.id;
    this.email = input.email;
    if (input.firstName || input.lastName) {
      this.firstName = input.firstName;
      this.lastName = input.lastName;
    } else {
      [this.firstName, this.lastName] = this.email.split('@')[0].split('.');
    }
    this.workspaces = input.workspaces ? input.workspaces.items.map((w) => w.workspace) : [];
    this.organizationsUser = input.organizations ? input.organizations.items : [];

    this.hasWarehouseCredentials = this.hasWarehouseCredentials.bind(this);
    this.createOrUpdateWarehouseCredentials = this.createOrUpdateWarehouseCredentials.bind(this);
    this.resetWarehouseCredentials = this.resetWarehouseCredentials.bind(this);
  }

  async hasWarehouseCredentials(
    organization: Organization,
  ): Promise<{ id: string; hasWarehouseCredentials: boolean }> {
    // vinhf migrate ID of OrganizationUser to be ${orgId}.${userId}
    const data = await graphql('listOrganizationUsersByUser', {
      userId: this.id,
      organizationId: { eq: organization.id },
    });
    if (data.items.length !== 1) throw new Error('BEEM220628095954');
    const { id, hasWarehouseCredentials } = data.items[0];
    return { id, hasWarehouseCredentials: hasWarehouseCredentials ?? false };
  }

  async createOrUpdateWarehouseCredentials(organization: Organization): Promise<string> {
    // find the workspaces in this org that this user has access to
    let workspaces = await Workspace.getWorkspacesByUser(this.id);
    workspaces = workspaces.filter((el) => el.organization.id === organization.id);

    // call the beem agent to create the warehouse user
    // or update (aka add/remove) the workspace groups
    // or delete the user if no more workspaces
    const data = await graphql('upsertUserWarehouseCredentials', {
      query: {
        orgId: organization.cloudResourceId,
        userEmail: this.email,
        workspaceSeqs: workspaces.map((el) => addBackendSuffix(String(el.seq))),
      },
    });

    // set the flag that this user now has warehouse creds for this org
    const { id, hasWarehouseCredentials } = await this.hasWarehouseCredentials(organization);
    if (workspaces.length > 0 && !hasWarehouseCredentials)
      await graphql('updateOrganizationUser', { input: { id, hasWarehouseCredentials: true } });
    else if (workspaces.length === 0 && hasWarehouseCredentials)
      await graphql('updateOrganizationUser', { input: { id, hasWarehouseCredentials: false } });

    return data.password;
  }

  async resetWarehouseCredentials(organization: Organization): Promise<string> {
    const data = await graphql('upsertUserWarehouseCredentials', {
      query: {
        orgId: organization.cloudResourceId,
        userEmail: this.email,
      },
    });
    return data.password;
  }

  static async getUserById(id: string): Promise<User> {
    logger.info(id, { label: 'app.model.user.getUserById.id' });
    const data = await graphql('getUser', { id });
    return new User(data);
  }

  static async getCurrentAuthenticatedUser(): Promise<User | null> {
    let cognitoUser: CognitoUser;
    try {
      cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      logger.info({
        label: 'app.services.user.getCurrentAuthenticatedUser.cognitoUser',
        message: cognitoUser,
      });
    } catch (err) {
      if (typeof err === 'string' && err.includes('not authenticated')) {
        logger.debug({
          label: 'app.services.user.getCurrentAuthenticatedUser.error',
          message: err,
        });
      } else {
        handleUserError(err, `${ns}.getCurrentAuthenticatedUser.error`);
      }
      return null;
    }

    const id = cognitoUser.getUsername();
    const user = await User.getUserById(id);

    return user;
  }

  static async createIfNotExist(email: string, workspace: Workspace): Promise<User> {
    const { organization } = workspace;

    const { items } = await graphql('listUsersByEmail', { email });

    let userId: string;
    let newAuth0Username = '';
    if (items.length === 0) {
      // create the user in auth0 and add the user record in dynamodb
      const currentUser = await User.getCurrentAuthenticatedUser();
      assertIsDefined(currentUser, 'BEEM240913150943');
      if (Date.now() > 0) {
        ({ id: newAuth0Username } = await graphql('auth0CreateUser', {
          query: {
            email,
            userMetadata: JSON.stringify({
              organization_name: organization.name,
              workspace_name: workspace.name,
              invited_by_name: currentUser.firstName ?? '(name_not_available)',
              invited_by_email: currentUser.email,
              use_invitation_email_template: true,
            }),
          },
        }));
        userId = getCognitoUsernameFromAuth0UserId(newAuth0Username);
      } else {
        ({ id: userId } = await graphql('createCognitoUser', { query: { email } }));
      }
      const names = email.split('@')[0];
      await graphql('createUser', {
        input: {
          id: userId,
          email,
          firstName: names.split('.')[0],
          lastName: names.split('.')[1],
        },
      });
    } else {
      userId = items[0].id;
    }

    // link the user with the org
    await graphql('createOrganizationUser', {
      input: { id: `${organization.id}#${userId}`, organizationId: organization.id, userId },
    });

    // add the user to the workspace
    const ws = await Workspace.getWorkspaceById(workspace.id);
    await ws.addMembers([userId]);

    if (newAuth0Username) {
      // trigger auth0 to send the Reset Password email to the new user
      await graphql('auth0ChangePassword', { query: { email } });
      await graphql('auth0UpdateUserMetadata', {
        query: {
          username: newAuth0Username,
          userMetadata: JSON.stringify({ use_invitation_email_template: false }),
        },
      });
    }

    return User.getUserById(userId);
  }

  static async updateUserDetails(user: A.UpdateUserMutationVariables['input']): Promise<void> {
    await graphql('updateUser', {
      input: { ...user },
    });
  }
}
