import * as React from 'react';

export default (props) => {
  return (
    <svg
      width={props.svgWidth || 24}
      height={props.svgHeight || 24}
      viewBox={props.svgViewBox || '0 0 24 24'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7C16.0258 7 18.755 10.2413 19.6657 11.5045C19.8817 11.8041 19.8817 12.1959 19.6657 12.4955C18.755 13.7587 16.0258 17 12 17C7.97424 17 5.24502 13.7587 4.3343 12.4955C4.11831 12.1959 4.11831 11.8041 4.3343 11.5045C5.24502 10.2413 7.97424 7 12 7Z"
        stroke="white"
        strokeWidth="2"
      />
      <circle cx="12" cy="12" r="2" stroke="white" strokeWidth="2" />
    </svg>
  );
};
