import * as React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      opacity="0.1"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.1675 5.80403C1.51977 3.38732 3.38385 1.50648 5.7997 1.1484C9.29676 0.630076 14.6484 0 20 0C25.3335 0 30.667 0.625815 34.1647 1.14313C36.5988 1.50314 38.4706 3.40573 38.8189 5.84151C39.343 9.50671 40 15.0818 40 20C40 24.9182 39.343 30.4933 38.8189 34.1585C38.4706 36.5943 36.5988 38.4969 34.1647 38.8569C30.667 39.3742 25.3335 40 20 40C14.6484 40 9.29676 39.3699 5.7997 38.8516C3.38384 38.4935 1.51977 36.6127 1.1675 34.196C0.64569 30.6161 0 25.1466 0 20C0 14.8534 0.645691 9.38386 1.1675 5.80403Z"
      fill="#0067F4"
    />
    <path d="M15 20L25 20" stroke="#0067F4" stroke-width="2" stroke-linecap="round" />
    <path d="M20 15L20 25" stroke="#0067F4" stroke-width="2" stroke-linecap="round" />
  </svg>
);
