export default {
  "visualization": {
    "form": {
      "title": "Visualization Title",
      "type": "Visualization Type",
      "x_axis": "Column",
      "y_axis": "Y axis"
    },
    "create_new": "Create new visualization",
    "create_viz": "Create visualization"
  }
};
