import * as React from 'react';

export default () => (
  <svg width="56" height="56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="no-filter-a" fill="#fff">
      <rect width="16" height="16" rx="3" />
    </mask>
    <rect width="16" height="16" rx="3" stroke="#0067F4" strokeWidth="8" mask="url(#no-filter-a)" />
    <mask id="no-filter-b" fill="#fff">
      <rect y="20" width="16" height="16" rx="3" />
    </mask>
    <rect
      y="20"
      width="16"
      height="16"
      rx="3"
      stroke="#0067F4"
      strokeWidth="8"
      mask="url(#no-filter-b)"
    />
    <mask id="no-filter-c" fill="#fff">
      <rect y="40" width="16" height="16" rx="3" />
    </mask>
    <rect
      y="40"
      width="16"
      height="16"
      rx="3"
      stroke="#0067F4"
      strokeWidth="8"
      mask="url(#no-filter-c)"
    />
    <mask id="no-filter-d" fill="#fff">
      <rect x="20" width="36" height="16" rx="3" />
    </mask>
    <rect
      x="20"
      width="36"
      height="16"
      rx="3"
      stroke="#0067F4"
      strokeWidth="8"
      mask="url(#no-filter-d)"
    />
    <mask id="no-filter-e" fill="#fff">
      <rect x="20" y="20" width="36" height="16" rx="3" />
    </mask>
    <rect
      x="20"
      y="20"
      width="36"
      height="16"
      rx="3"
      stroke="#0067F4"
      strokeWidth="8"
      mask="url(#no-filter-e)"
    />
    <mask id="no-filter-f" fill="#fff">
      <rect x="20" y="40" width="36" height="16" rx="3" />
    </mask>
    <rect
      x="20"
      y="40"
      width="36"
      height="16"
      rx="3"
      stroke="#0067F4"
      strokeWidth="8"
      mask="url(#no-filter-f)"
    />
  </svg>
);
