import moment from 'moment';
import { getReduxState } from '@/utils/reduxSaga';
import { assertIsString } from '@/utils/typeChecks';

export enum SCHEDULED_TRIGGER_OCCURRENCES {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export type ScheduledTriggerComponents = {
  occurrence: SCHEDULED_TRIGGER_OCCURRENCES;
  day: string; // '?' for daily, 'sun'...'sat' for weekly, or '1'...'31' for monthly
  time: string; // format 'hh:mm A' (eg. 09:15 AM) because it's how the Time Picker from antd produces the value
};

export type TriggerDetails = {
  cron: string; // even when the trigger is Manual, this should store the default value, or the value used previously when the trigger was Schedule or Cron Exp
  timezone: string; // this must always have a value, unless it's before the implementation of EventBridge Scheduler, in which case it's an empty string
};

function setDefaults(input: Partial<TriggerDetails>): TriggerDetails {
  let { cron, timezone } = input;
  if (!cron) {
    cron = '0 0 * * ? *';
    const { currentOrganizationTimezone } = getReduxState().organization;
    assertIsString(currentOrganizationTimezone, 'BEEM240128174605');
    timezone = currentOrganizationTimezone;
  } else if (!timezone) {
    // this only happens for the old EventBridge Rules before we converted to use Event Bridge Scheduler in 2024
    // because previous to that, all cron expressions were in UTC, and all accounts were in MTL
    // so, we want to auto-convert the "common" crons from UTC into MTL if possible
    // once migration is done (before Mar 2024), this will only be used in case of historical versions
    const ccs = cron.split(' ');
    if (ccs[2] === '*') {
      // daily
      if (/^\d+$/.test(ccs[1])) {
        //  single hour ie. 0 5 * * ? *
        let h = parseInt(ccs[1], 10) - 5;
        if (h < 0) h += 24;
        ccs[1] = String(h);
        cron = ccs.join(' ');
      } else if (/^\d+-\d+$/.test(ccs[1])) {
        //  range hour ie. 0 5-8 * * ? *
        const hs = ccs[1].split('-').map((el) => parseInt(el, 10) - 5);
        if (hs[0] >= 0) {
          ccs[1] = hs.join('-');
          cron = ccs.join(' ');
        }
      } else if (/^\d+(,\d+)+$/.test(ccs[1])) {
        //  comma hour ie. 0 5,8,9 * * ? *
        const hs = ccs[1]
          .split(',')
          .map((el) => parseInt(el, 10) - 5)
          .map((el) => (el < 0 ? el + 24 : el))
          .sort((a, b) => a - b);
        ccs[1] = hs.join(',');
        cron = ccs.join(' ');
      }
    } else if (/^\d+$/.test(ccs[2]) && ccs[3] === '*') {
      // monthly single day
      if (/^\d+$/.test(ccs[1])) {
        //  single hour ie. 0 5 10 * ? *
        let h = parseInt(ccs[1], 10) - 5;
        let d = parseInt(ccs[2], 10);
        if (h < 0) {
          h += 24;
          d -= 1;
          if (d < 1) {
            d = 28;
          }
        }
        ccs[1] = String(h);
        ccs[2] = String(d);
        cron = ccs.join(' ');
      }
    } else if (/^[0-9A-Za-z]+$/.test(ccs[4]) && ccs[3] === '*') {
      // weekly single day
      ccs[4] = ccs[4].toLowerCase();
      if (/^\d+$/.test(ccs[1])) {
        //  single hour ie. 0 5 ? * mon *
        let h = parseInt(ccs[1], 10) - 5;
        const mw = { sun: 1, mon: 2, tue: 3, wed: 4, thu: 5, fri: 6, sat: 7 };
        // @ts-ignore due to removing suppressImplicitAnyIndexErrors after upgrading typescript to 5.7.3
        let w = /^\d+$/.test(ccs[4]) ? parseInt(ccs[4], 10) : mw[ccs[4]];
        if (h < 0) {
          h += 24;
          w -= 1;
          if (w < 1) {
            w = 7;
          }
        }
        ccs[1] = String(h);
        if (/^\d+$/.test(ccs[4])) {
          ccs[4] = String(w);
          cron = ccs.join(' ');
        } else {
          // @ts-ignore due to removing suppressImplicitAnyIndexErrors after upgrading typescript to 5.7.3
          ccs[4] = Object.keys(mw).find((el) => mw[el] === w) ?? '';
          if (ccs[4]) cron = ccs.join(' ');
        }
      }
    }
    if (cron === input.cron) timezone = 'UTC';
    else timezone = 'America/Montreal';
  }
  return { cron, timezone };
}

function getCronFromScheduledTriggerComponents(input: ScheduledTriggerComponents): string {
  const { occurrence, day, time } = input;
  const cronTime = moment(time, ['hh:mm A']).format('HH:mm').split(':').reverse().join(' ');
  if (occurrence === SCHEDULED_TRIGGER_OCCURRENCES.DAILY) return `${cronTime} * * ? *`;
  if (occurrence === SCHEDULED_TRIGGER_OCCURRENCES.WEEKLY) return `${cronTime} ? * ${day} *`;
  if (occurrence === SCHEDULED_TRIGGER_OCCURRENCES.MONTHLY) return `${cronTime} ${day} * ? *`;
  throw new Error(`BEEM220725122958 - ${JSON.stringify(input)}`);
}

function getScheduledTriggerComponentsFromCron(cron: string): ScheduledTriggerComponents {
  const [mm, hh, d, m, w, y] = cron.split(' ');
  const time = moment(`${hh}:${mm}`, ['HH:mm']).format('hh:mm A');
  if (y === '*' && m === '*') {
    if (w === '?') {
      if (d === '*') return { occurrence: SCHEDULED_TRIGGER_OCCURRENCES.DAILY, day: '?', time };
      return { occurrence: SCHEDULED_TRIGGER_OCCURRENCES.MONTHLY, day: d, time };
    }
    if (d === '?') return { occurrence: SCHEDULED_TRIGGER_OCCURRENCES.WEEKLY, day: w, time };
  }
  throw new Error(`BEEM220725122959 - ${cron}`);
}

export default {
  setDefaults,
  getCronFromScheduledTriggerComponents,
  getScheduledTriggerComponentsFromCron,
};
