import { VisualizationType } from '@/api';
import { graphql } from '@/utils/graphql';
import { logger } from '@/utils/logger';

const ns = 'app.services.visualization';

type BarChartVisualizationConfiguration = {
  groupBy: string;
};

type LineChartVisualizationConfiguration = {
  toBeAdded: number;
};

type VisualizationConfiguration<T extends VisualizationType> = T extends VisualizationType.BAR_CHART
  ? BarChartVisualizationConfiguration
  : T extends VisualizationType.LINE_CHART
  ? LineChartVisualizationConfiguration
  : never;

function assertIsVisualizationConfiguration<T extends VisualizationType>(
  type: T,
  val: unknown,
  label: string,
): asserts val is VisualizationConfiguration<T> {
  if (
    !(
      typeof val === 'object' &&
      val !== null &&
      ((type === VisualizationType.BAR_CHART &&
        'groupBy' in val &&
        Object.hasOwn(val, 'groupBy') &&
        typeof val.groupBy === 'string') ||
        (type === VisualizationType.LINE_CHART &&
          'toBeAdded' in val &&
          Object.hasOwn(val, 'toBeAdded') &&
          typeof val.toBeAdded === 'number'))
    )
  )
    throw new Error(`assertIsVisualizationConfiguration ${type} | ${val} [${label}]`);
}

type ConstructorInput = {
  id: string;
  name: string;
  datasetId: string;
  type: VisualizationType;
  configurations: BarChartVisualizationConfiguration | LineChartVisualizationConfiguration;
};

export default class Visualization {
  readonly id: string;

  readonly name: string;

  readonly datasetId: string;

  #typeAndConfigurations:
    | {
        type: VisualizationType.BAR_CHART;
        configurations: BarChartVisualizationConfiguration;
      }
    | {
        type: VisualizationType.LINE_CHART;
        configurations: LineChartVisualizationConfiguration;
      };

  get type(): VisualizationType {
    return this.#typeAndConfigurations.type;
  }

  private constructor(input: ConstructorInput) {
    this.id = input.id;
    this.name = input.name;
    this.datasetId = input.datasetId;

    const { type, configurations } = input;
    if (type === VisualizationType.BAR_CHART) {
      assertIsVisualizationConfiguration(type, configurations, 'BEEM250119172100');
      this.#typeAndConfigurations = { type, configurations };
    } else if (type === VisualizationType.LINE_CHART) {
      assertIsVisualizationConfiguration(type, configurations, 'BEEM250119172101');
      this.#typeAndConfigurations = { type, configurations };
    } else {
      throw new Error('BEEM250119172000');
    }

    this.getVisualizationQuery = this.getVisualizationQuery.bind(this);
  }

  getVisualizationQuery(datasetQuery: string): string {
    const { type, configurations } = this.#typeAndConfigurations;

    if (type === VisualizationType.BAR_CHART) {
      const column = configurations.groupBy;

      // vinh1688 now move duplicated code
      const cleanedQuery = datasetQuery.trim().replace(/;$/, '');
      const limitValue = 1000;
      const queryWithLimit = `${cleanedQuery} LIMIT ${limitValue}`;
      const finalQuery = `
            SELECT ${column} AS "group", COUNT(*) AS "count"
            FROM (${queryWithLimit}) nested
            GROUP BY ${column};
        `;
      return finalQuery;
    }

    throw new Error('BEEM25011205300');
  }

  static async getVisualizationsByDataset(datasetId: string): Promise<Visualization[]> {
    logger.info({ label: `${ns}.getVisualizationsByDataset.datasetId`, message: datasetId });
    const data = await graphql('visualizationsByDataset', { datasetId });
    return data.items.map(
      (el) => new Visualization({ ...el, configurations: JSON.parse(el.configurations) }),
    );
  }
}
