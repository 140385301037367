import type { SqlQueryResults } from '@/services/types';
import type Visualization from '@/services/visualization';
import type { InvokeResult, ReduxSagaModel } from './types';
import { VisualizationType } from '@/api';
import Dataset from '@/services/dataset';
import { sgcall, sgselect } from '@/utils/reduxSaga';
import { handleUserError } from '@/utils/handleError';

const ns = 'app.models.visualization';

export type State = {
  visualizations: Visualization[];
  vizConfig: any;
  invokeRunVisualizationQueryResultByVisualizationId: Record<string, InvokeResult<SqlQueryResults>>;
};

const initialState = {
  visualizations: [],
  vizConfig: null,
  invokeRunVisualizationQueryResultByVisualizationId: {},
};

type SaveVisualizationsAction = {
  type: 'saveVisualizations';
  payload: Pick<State, 'visualizations'>;
};

type SaveVizConfigAction = {
  type: 'saveVizConfig';
  payload: Pick<State, 'vizConfig'>;
};

type SaveInvokeRunVisualizationQueryResultAction = {
  type: 'saveInvokeRunVisualizationQueryResult';
  payload: { visualizationId: string; invokeResult: InvokeResult<SqlQueryResults> };
};

type SaveInvokeRunVisualizationQueryResultByVisualizationIdAction = {
  type: 'saveInvokeRunVisualizationQueryResultByVisualizationId';
  payload: Pick<State, 'invokeRunVisualizationQueryResultByVisualizationId'>;
};

export type RunVisualizationQueryEffectPayload = { visualization: Visualization };

const VisualizationModel: ReduxSagaModel<
  State,
  {
    saveVisualizations: SaveVisualizationsAction;
    saveVizConfig: SaveVizConfigAction;
    saveInvokeRunVisualizationQueryResult: SaveInvokeRunVisualizationQueryResultAction;
    saveInvokeRunVisualizationQueryResultByVisualizationId: SaveInvokeRunVisualizationQueryResultByVisualizationIdAction;
  }
> = {
  namespace: 'visualization',
  state: initialState,
  effects: {
    *fetch({ payload }: { payload: { record: Dataset } }, { put }) {
      try {
        const visualizations = yield* sgcall(payload.record.getVisualizations);
        yield put<SaveVisualizationsAction>({
          type: 'saveVisualizations',
          payload: { visualizations },
        });
      } catch (e) {
        // vinh1688 handle error
      }
    },
    *runVisualizationQuery({ payload }: { payload: RunVisualizationQueryEffectPayload }, { put }) {
      try {
        const { visualization } = payload;
        const sqlQuery = yield* sgselect((s) => s.dataset.sqlQuery, 'BEEM250117074115');
        const vizQuery = visualization.getVisualizationQuery(sqlQuery);

        const rows = yield* sgcall(() => Dataset.testSqlQuery(vizQuery, 1000, 0, [], []));
        yield put<SaveInvokeRunVisualizationQueryResultAction>({
          type: 'saveInvokeRunVisualizationQueryResult',
          payload: {
            visualizationId: payload.visualization.id,
            invokeResult: { success: true, data: rows },
          },
        });
      } catch (e) {
        yield put<SaveInvokeRunVisualizationQueryResultAction>({
          type: 'saveInvokeRunVisualizationQueryResult',
          payload: {
            visualizationId: payload.visualization.id,
            invokeResult: {
              success: false,
              error: handleUserError(e, `${ns}.runVisualizationQuery.error`),
            },
          },
        });
      }
    },
    *create(
      {
        payload,
      }: {
        payload: {
          name: string;
          type: VisualizationType;
          configurations: string;
          workspaceId: string;
          datasetId: string;
        };
      },
      { put },
    ) {
      try {
        const data = yield* sgcall(() => Dataset.createDatasetVisualization(payload));

        yield put<SaveVizConfigAction>({
          type: 'saveVizConfig',
          payload: { vizConfig: { success: true, data } },
        });

        // vinh1688 now to trigger fetch effect
        const dataset = yield* sgcall(() => Dataset.getDatasetById(payload.datasetId));
        const visualizations = yield* sgcall(dataset.getVisualizations);
        yield put<SaveVisualizationsAction>({
          type: 'saveVisualizations',
          payload: { visualizations },
        });
      } catch (e) {
        console.error(e);
      }
    },
  },
  reducers: {
    resetAll() {
      return { ...initialState };
    },
    saveVisualizations(state, { payload }) {
      return { ...state, ...payload };
    },
    saveVizConfig(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeRunVisualizationQueryResult(state, { payload }) {
      return {
        ...state,
        invokeRunVisualizationQueryResultByVisualizationId: {
          ...state.invokeRunVisualizationQueryResultByVisualizationId,
          [payload.visualizationId]: payload.invokeResult,
        },
      };
    },
    saveInvokeRunVisualizationQueryResultByVisualizationId(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default VisualizationModel;
