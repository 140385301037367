// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/codebuild/output/src1768299652/src/app/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__Root' */'@/layouts/Root'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/login-error",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'@/layouts/UserLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "name": "Login Error",
            "path": "/login-error",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__LoginError' */'/codebuild/output/src1768299652/src/app/src/pages/Account/LoginError'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'@/layouts/UserLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "name": "Login",
            "path": "/login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account' */'/codebuild/output/src1768299652/src/app/src/pages/Account'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/account/settings",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'@/layouts/BlankLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'@/layouts/BlankLayout'), loading: LoadingComponent}),
            "authority": [
              "admin",
              "user"
            ],
            "routes": [
              {
                "name": "Account Settings",
                "path": "/account/settings",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Settings' */'/codebuild/output/src1768299652/src/app/src/pages/Account/Settings'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/account",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'@/layouts/UserLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "name": "Forgot Password",
            "path": "/account/forgot-password",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Recover__ForgotPassword' */'/codebuild/output/src1768299652/src/app/src/pages/Account/Recover/ForgotPassword'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "First Login",
            "path": "/account/first-login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Recover__FirstLogin' */'/codebuild/output/src1768299652/src/app/src/pages/Account/Recover/FirstLogin'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Reset Password",
            "path": "/account/reset-password",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Recover__ResetPassword' */'/codebuild/output/src1768299652/src/app/src/pages/Account/Recover/ResetPassword'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "New Password",
            "path": "/account/new-password",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Recover__NewPassword' */'/codebuild/output/src1768299652/src/app/src/pages/Account/Recover/NewPassword'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Select Organization",
            "path": "/account/select-organization",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__SignIn__SelectOrganization' */'/codebuild/output/src1768299652/src/app/src/pages/Account/SignIn/SelectOrganization'), loading: LoadingComponent}),
            "authority": [
              "admin",
              "user"
            ],
            "exact": true
          },
          {
            "name": "Account Activation",
            "path": "/account/activation",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Recover__components__AccountActivation' */'/codebuild/output/src1768299652/src/app/src/pages/Account/Recover/components/AccountActivation'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'@/layouts/SecurityLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/catalog/dataobjects",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'@/layouts/BlankLayout'), loading: LoadingComponent}),
            "authority": [
              "admin",
              "user"
            ],
            "routes": [
              {
                "name": "[Data Object Name]",
                "icon": "database",
                "path": "/catalog/dataobjects/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Catalog__ViewDataObject' */'/codebuild/output/src1768299652/src/app/src/pages/Catalog/ViewDataObject'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src1768299652/src/app/src/pages/404'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/hub/datasets",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'@/layouts/BlankLayout'), loading: LoadingComponent}),
            "authority": [
              "admin",
              "user"
            ],
            "routes": [
              {
                "name": "View Dataset",
                "icon": "database",
                "path": "/hub/datasets/:id/view/:version",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Hub__ViewDataset' */'/codebuild/output/src1768299652/src/app/src/pages/Hub/ViewDataset'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__OwnerWorkspaceLayout' */'@/layouts/OwnerWorkspaceLayout'), loading: LoadingComponent}),
                "routes": [
                  {
                    "name": "Edit Dataset",
                    "icon": "code",
                    "path": "/hub/datasets/:id/edit/:version",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Hub__EditDataset' */'/codebuild/output/src1768299652/src/app/src/pages/Hub/EditDataset'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "View Dataset Test",
                    "icon": "database",
                    "path": "/hub/datasets/test/:id/view/:version",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Hub__ViewDatasetTest' */'/codebuild/output/src1768299652/src/app/src/pages/Hub/ViewDatasetTest'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "Edit Dataset Test",
                    "icon": "code",
                    "path": "/hub/datasets/test/:id/edit/:version",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Hub__EditDatasetTest' */'/codebuild/output/src1768299652/src/app/src/pages/Hub/EditDatasetTest'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src1768299652/src/app/src/pages/404'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/flows/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'@/layouts/BlankLayout'), loading: LoadingComponent}),
            "authority": [
              "admin",
              "user"
            ],
            "routes": [
              {
                "name": "View Flow",
                "icon": "flow",
                "path": "/flows/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flows__ViewFlow__index' */'/codebuild/output/src1768299652/src/app/src/pages/Flows/ViewFlow/index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src1768299652/src/app/src/pages/404'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/integrations/add",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'@/layouts/BlankLayout'), loading: LoadingComponent}),
            "authority": [
              "admin"
            ],
            "routes": [
              {
                "name": "Add integration",
                "path": "/integrations/add",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Integrations__AddIntegration' */'/codebuild/output/src1768299652/src/app/src/pages/Integrations/AddIntegration'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/integrations/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'@/layouts/BasicLayout'), loading: LoadingComponent}),
            "authority": [
              "admin"
            ],
            "routes": [
              {
                "name": "View integrations",
                "path": "/integrations/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Integrations__ViewIntegration' */'/codebuild/output/src1768299652/src/app/src/pages/Integrations/ViewIntegration'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "name": "Settings",
            "path": "/settings",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Settings' */'/codebuild/output/src1768299652/src/app/src/pages/Settings'), loading: LoadingComponent}),
            "authority": [
              "admin",
              "user"
            ],
            "exact": true
          },
          {
            "path": "/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'@/layouts/BasicLayout'), loading: LoadingComponent}),
            "authority": [
              "admin",
              "user"
            ],
            "routes": [
              {
                "path": "/",
                "redirect": "/catalog",
                "exact": true
              },
              {
                "name": "Catalog",
                "path": "/catalog",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Catalog' */'/codebuild/output/src1768299652/src/app/src/pages/Catalog'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "Hub shared datasets",
                "path": "/share/:workspaceId",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Hub__HubSharedDatasets' */'/codebuild/output/src1768299652/src/app/src/pages/Hub/HubSharedDatasets'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "Hub",
                "path": "/hub/:folderId",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Hub' */'/codebuild/output/src1768299652/src/app/src/pages/Hub'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "Hub Root",
                "path": "/hub",
                "exact": true
              },
              {
                "name": "Flows",
                "path": "/flows",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flows' */'/codebuild/output/src1768299652/src/app/src/pages/Flows'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "Members",
                "path": "/members",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Members' */'/codebuild/output/src1768299652/src/app/src/pages/Members'), loading: LoadingComponent}),
                "authority": [
                  "admin"
                ],
                "exact": true
              },
              {
                "name": "Monitor",
                "path": "/monitor",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Monitor' */'/codebuild/output/src1768299652/src/app/src/pages/Monitor'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "Integrations",
                "path": "/integrations",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Integrations' */'/codebuild/output/src1768299652/src/app/src/pages/Integrations'), loading: LoadingComponent}),
                "authority": [
                  "admin"
                ],
                "exact": true
              },
              {
                "name": "Connections",
                "path": "/connections",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Connections' */'/codebuild/output/src1768299652/src/app/src/pages/Connections'), loading: LoadingComponent}),
                "authority": [
                  "admin"
                ],
                "exact": true
              },
              {
                "name": "Insights",
                "path": "/insights",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Insights' */'/codebuild/output/src1768299652/src/app/src/pages/Insights'), loading: LoadingComponent}),
                "authority": [
                  "admin",
                  "user"
                ],
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src1768299652/src/app/src/pages/404'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src1768299652/src/app/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src1768299652/src/app/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
