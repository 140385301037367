import type { SqlQueryResults, SqlQueryRowCountResults } from '@/services/types';
import type { InvokeResult, ReduxSagaModel, ReduxState } from './types';
import type DatasetViewState from './objects/datasetViewState';
import type { DatasetEditStateProps } from './objects/datasetEditState';
import Dataset from '@/services/dataset';
import DatasetTest from '@/services/datasetTest';
import { waitForRedshiftQueryUntilDoneAndReturnResults } from '@/services/dataset.common';
import { generateEffectsForGetContentCompatibleType } from './common/getContent';
import DatasetEditState from './objects/datasetEditState';
import { sgcall, sgselect } from '@/utils/reduxSaga';
import { handleUserError } from '@/utils/handleError';
import { assertIsDataset, assertIsDefined, assertIsString } from '@/utils/typeChecks';

const ns = 'app.models.dataset';
const REDUX_NAMESPACE = 'dataset';

export interface State {
  current: Dataset | null;
  rowCount: number | null;
  previewRowCount: InvokeResult<SqlQueryRowCountResults> | null;
  records: SqlQueryResults | null;
  consolidatedTestResults: SqlQueryResults | null;
  viewState: DatasetViewState | null;
  editState: DatasetEditState | null;
  sqlQuery: string | null;
  previewChartConfig: any | null;
  invokeUpdateResult: InvokeResult<Dataset> | null;
  invokeCreateResult: InvokeResult<string> | null;
  invokeRenameResult: InvokeResult<string> | null;
  invokeMoveResult: InvokeResult<null> | null;
  invokeDeleteResult: InvokeResult<null> | null;
  invokeRefreshResult: InvokeResult<null> | null;
  invokePreviewResult: InvokeResult<SqlQueryResults> | null;
  invokeVizPreviewResult: InvokeResult<SqlQueryResults> | null;
  invokeSaveLocallyResult: InvokeResult<null> | null;
  invokeDeployRemotelyResult: InvokeResult<{ unchanged: boolean }> | null;
}

const initialState = {
  current: null,
  rowCount: null,
  previewRowCount: null,
  records: null,
  consolidatedTestResults: null,
  viewState: null,
  editState: null,
  sqlQuery: null,
  previewChartConfig: null,
  invokeUpdateResult: null,
  invokeCreateResult: null,
  invokeRenameResult: null,
  invokeMoveResult: null,
  invokeDeleteResult: null,
  invokeRefreshResult: null,
  invokePreviewResult: null,
  invokeVizPreviewResult: null,
  invokeSaveLocallyResult: null,
  invokeDeployRemotelyResult: null,
};

interface SaveCurrentAction {
  type: 'saveCurrent';
  payload: Pick<State, 'current'>;
}

interface SaveRowCountAction {
  type: 'saveRowCount';
  payload: Pick<State, 'rowCount'>;
}
interface SavePreviewRowCountAction {
  type: 'savePreviewRowCount';
  payload: Pick<State, 'previewRowCount'>;
}

interface SaveRecordsAction {
  type: 'saveRecords';
  payload: Pick<State, 'records'>;
}

interface SaveConsolidatedTestResultsAction {
  type: 'saveConsolidatedTestResults';
  payload: Pick<State, 'consolidatedTestResults'>;
}

interface SaveViewStateAction {
  type: 'saveViewState';
  payload: Pick<State, 'viewState'>;
}

interface SaveEditStateAction {
  type: 'saveEditState';
  payload: Pick<State, 'editState'>;
}

interface SaveSqlQueryAction {
  type: 'saveSqlQuery';
  payload: Pick<State, 'sqlQuery'>;
}
interface SavePreviewChartConfigAction {
  type: 'savePreviewChartConfig';
  payload: Pick<State, 'previewChartConfig'>;
}

interface SaveInvokeUpdateResultAction {
  type: 'saveInvokeUpdateResult';
  payload: Pick<State, 'invokeUpdateResult'>;
}

interface SaveInvokeCreateResultAction {
  type: 'saveInvokeCreateResult';
  payload: Pick<State, 'invokeCreateResult'>;
}

interface SaveInvokeRenameResultAction {
  type: 'saveInvokeRenameResult';
  payload: Pick<State, 'invokeRenameResult'>;
}

interface SaveInvokeMoveResultAction {
  type: 'saveInvokeMoveResult';
  payload: Pick<State, 'invokeMoveResult'>;
}

interface SaveInvokeDeleteResultAction {
  type: 'saveInvokeDeleteResult';
  payload: Pick<State, 'invokeDeleteResult'>;
}

interface SaveInvokeRefreshResultAction {
  type: 'saveInvokeRefreshResult';
  payload: Pick<State, 'invokeRefreshResult'>;
}

interface SaveInvokePreviewResultAction {
  type: 'saveInvokePreviewResult';
  payload: Pick<State, 'invokePreviewResult'>;
}
interface SaveInvokeVizPreviewResultAction {
  type: 'saveInvokeVizPreviewResult';
  payload: Pick<State, 'invokeVizPreviewResult'>;
}

interface SaveInvokeSaveLocallyResultAction {
  type: 'saveInvokeSaveLocallyResult';
  payload: Pick<State, 'invokeSaveLocallyResult'>;
}

interface SaveInvokeDeployRemotelyResultAction {
  type: 'saveInvokeDeployRemotelyResult';
  payload: Pick<State, 'invokeDeployRemotelyResult'>;
}

const Dataset2Model: ReduxSagaModel<
  State,
  {
    saveCurrent: SaveCurrentAction;
    saveRowCount: SaveRowCountAction;
    savePreviewRowCount: SavePreviewRowCountAction;
    saveRecords: SaveRecordsAction;
    saveConsolidatedTestResults: SaveConsolidatedTestResultsAction;
    saveViewState: SaveViewStateAction;
    saveEditState: SaveEditStateAction;
    saveSqlQuery: SaveSqlQueryAction;
    savePreviewChartConfig: SavePreviewChartConfigAction;
    saveInvokeUpdateResult: SaveInvokeUpdateResultAction;
    saveInvokeCreateResult: SaveInvokeCreateResultAction;
    saveInvokeRenameResult: SaveInvokeRenameResultAction;
    saveInvokeMoveResult: SaveInvokeMoveResultAction;
    saveInvokeDeleteResult: SaveInvokeDeleteResultAction;
    saveInvokeRefreshResult: SaveInvokeRefreshResultAction;
    saveInvokePreviewResult: SaveInvokePreviewResultAction;
    saveInvokeVizPreviewResult: SaveInvokeVizPreviewResultAction;
    saveInvokeSaveLocallyResult: SaveInvokeSaveLocallyResultAction;
    saveInvokeDeployRemotelyResult: SaveInvokeDeployRemotelyResultAction;
  }
> = {
  namespace: REDUX_NAMESPACE,
  state: initialState,
  effects: {
    *get(
      { payload }: { payload: { id: string; version?: number; timestamp?: number } },
      { call, put },
    ) {
      try {
        yield put<SaveViewStateAction>({ type: 'saveViewState', payload: { viewState: null } });
        const dataset = yield call(Dataset.getDatasetById, payload.id, payload.version);
        assertIsDataset(dataset, 'BEEM220717145512');
        dataset.timestamp = payload.timestamp;
        yield put<SaveCurrentAction>({ type: 'saveCurrent', payload: { current: dataset } });
      } catch (e) {
        handleUserError(e, `${ns}.get.error`);
        yield put<SaveCurrentAction>({ type: 'saveCurrent', payload: { current: null } });
      }
    },
    ...generateEffectsForGetContentCompatibleType<Dataset>(REDUX_NAMESPACE),
    *getConsolidatedTestResults(
      { payload }: { payload: { current: Dataset; pageSize: number; currentPage: number } },
      { put },
    ) {
      try {
        const { current, pageSize, currentPage } = payload;
        if (!current) return;
        const temp = yield* sgcall(() =>
          current.getConsolidatedTestResults(pageSize, (currentPage - 1) * pageSize),
        );
        let data = yield* sgcall(() => waitForRedshiftQueryUntilDoneAndReturnResults(temp));
        const testNamesById: Record<string, string> = {};
        for (let i = 0; i < data.length; i += 1) {
          const testId = data[i].test_uuid;
          assertIsString(testId, 'BEEM230828215747');
          if (!testNamesById[testId]) {
            const { name: testName } = yield* sgcall(() => DatasetTest.getDatasetTestById(testId));
            testNamesById[testId] = testName;
          }
        }
        data = data.map((el) => ({ ...el, test_name: testNamesById[el.test_uuid as string] }));
        yield put<SaveConsolidatedTestResultsAction>({
          type: 'saveConsolidatedTestResults',
          payload: { consolidatedTestResults: data },
        });
      } catch (e) {
        handleUserError(e, `${ns}.getConsolidatedTestResults.error`);
        yield put<SaveConsolidatedTestResultsAction>({
          type: 'saveConsolidatedTestResults',
          payload: { consolidatedTestResults: null },
        });
      }
    },
    *initEditState({ payload }: { payload: { version: number } }, { put, select }) {
      const dataset = yield select((s: ReduxState) => s.dataset.current);
      assertIsDataset(dataset, 'BEEM220717191005');

      const sqlQueryEtcChanges = yield* sgcall(dataset.getSqlQueryEtcChanges);

      let initial: DatasetEditStateProps;
      if (dataset.isDraft) {
        initial = DatasetEditState.getDefaultState();
      } else {
        const temp2 = sqlQueryEtcChanges.find((el, i) =>
          payload.version ? el.timestamp === payload.version : i === 0,
        );
        assertIsDefined(temp2, 'BEEM220919111827');
        initial = temp2;
      }

      const current: DatasetEditStateProps = { ...initial };

      const saved: DatasetEditStateProps | null =
        sqlQueryEtcChanges.length > 0 ? { ...sqlQueryEtcChanges[0] } : null;

      const deployedSqlQueryEtc = sqlQueryEtcChanges.find((el) => el.deployed);
      const deployed: DatasetEditStateProps | null = deployedSqlQueryEtc
        ? { ...deployedSqlQueryEtc }
        : null;

      yield put<SaveEditStateAction>({
        type: 'saveEditState',
        payload: { editState: new DatasetEditState({ initial, current, saved, deployed }) },
      });
    },
    *setEditStateCurrentValue({ payload }: { payload: Partial<DatasetEditStateProps> }, { put }) {
      const editState = yield* sgselect((s) => s.dataset.editState, 'BEEM220920094517');
      yield put<SaveEditStateAction>({
        type: 'saveEditState',
        payload: {
          editState: new DatasetEditState({
            ...editState,
            current: { ...editState.current, ...payload },
          }),
        },
      });
    },
    *invokeCreate(
      { payload }: { payload: { name: string; inFolderId: string; workspaceId: string } },
      { call, put },
    ) {
      try {
        const newDatasetId = yield call(Dataset.create, {
          name: payload.name,
          folderId: payload.inFolderId,
          workspaceId: payload.workspaceId,
        });
        yield put({
          type: 'saveInvokeCreateResult',
          payload: { invokeCreateResult: { success: true, data: newDatasetId } },
        });
      } catch (e) {
        yield put({
          type: 'saveInvokeCreateResult',
          payload: {
            invokeCreateResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeCreate.error`),
            },
          },
        });
      }
    },
    *invokeRename({ payload }: { payload: { record: Dataset; name: string } }, { call, put }) {
      try {
        yield call(payload.record.update, { name: payload.name });

        // Reshare workspaces to fix broken link
        const sharedWorkspaces = yield* sgcall(payload.record.getSharedWithWorkspaces);
        yield* sgcall(() => payload.record.share(sharedWorkspaces));

        yield put<SaveInvokeRenameResultAction>({
          type: 'saveInvokeRenameResult',
          payload: { invokeRenameResult: { success: true, data: payload.record.folderId } },
        });
      } catch (e) {
        yield put<SaveInvokeRenameResultAction>({
          type: 'saveInvokeRenameResult',
          payload: {
            invokeRenameResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeRename.error`),
            },
          },
        });
      }
    },
    *invokeMove({ payload }: { payload: { record: Dataset; toFolderId: string } }, { call, put }) {
      try {
        yield call(payload.record.update, { folderId: payload.toFolderId });
        yield put<SaveInvokeMoveResultAction>({
          type: 'saveInvokeMoveResult',
          payload: { invokeMoveResult: { success: true, data: null } },
        });
      } catch (e) {
        yield put<SaveInvokeMoveResultAction>({
          type: 'saveInvokeMoveResult',
          payload: {
            invokeMoveResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeMove.error`),
            },
          },
        });
      }
    },
    /*
     * Enable updating the dataset's `description` and `enableSearchByAI` fields.
     */
    *invokeUpdate(
      {
        payload,
      }: {
        payload: {
          record: Dataset;
          updatedValues: Pick<Dataset, 'description' | 'enableSearchByAI'>;
        };
      },
      { put },
    ) {
      try {
        const data = yield* sgcall(() => payload.record.update({ ...payload.updatedValues }));
        yield put<SaveInvokeUpdateResultAction>({
          type: 'saveInvokeUpdateResult',
          payload: { invokeUpdateResult: { success: true, data } },
        });
      } catch (e) {
        yield put<SaveInvokeUpdateResultAction>({
          type: 'saveInvokeUpdateResult',
          payload: {
            invokeUpdateResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeUpdate.error`),
            },
          },
        });
      }
    },
    *invokeDelete({ payload }: { payload: { id: string } }, { call, put }) {
      try {
        yield call(Dataset.delete, payload);

        yield put<SaveInvokeDeleteResultAction>({
          type: 'saveInvokeDeleteResult',
          payload: { invokeDeleteResult: { success: true, data: null } },
        });
      } catch (e) {
        yield put<SaveInvokeDeleteResultAction>({
          type: 'saveInvokeDeleteResult',
          payload: {
            invokeDeleteResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeDelete.error`),
            },
          },
        });
      }
    },
    *invokeRefresh({ payload }: { payload: { cloudResourceId: string } }, { call, put }) {
      try {
        yield call(Dataset.refresh, payload.cloudResourceId);
        yield put<SaveInvokeRefreshResultAction>({
          type: 'saveInvokeRefreshResult',
          payload: { invokeRefreshResult: { success: true, data: null } },
        });
      } catch (e) {
        yield put<SaveInvokeRefreshResultAction>({
          type: 'saveInvokeRefreshResult',
          payload: {
            invokeRefreshResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeRefresh.error`),
            },
          },
        });
      }
    },
    *invokePreview(
      {
        payload,
      }: {
        payload: { code: string; pageSize: number; currentPage: number | null };
      },
      { put },
    ) {
      try {
        const rs = yield* sgcall(() =>
          Dataset.testSqlQuery(
            payload.code,
            payload.pageSize,
            ((payload.currentPage ?? 1) - 1) * payload.pageSize,
            [],
            [],
          ),
        );
        const rowCountResponse = yield* sgcall(() =>
          Dataset.testSqlQuery(payload.code, 0, 0, [], []),
        );

        const rowCount =
          payload.pageSize === 100 ? 100 : (rowCountResponse?.[0]?.count as number) ?? 0;

        const data = rs.map((el, i) => ({ _beem_row_number: i + 1, ...el })) as SqlQueryResults;
        yield put<SaveInvokePreviewResultAction>({
          type: 'saveInvokePreviewResult',
          payload: { invokePreviewResult: { success: true, data } },
        });
        yield put<SavePreviewRowCountAction>({
          type: 'savePreviewRowCount',
          payload: { previewRowCount: { success: true, data: { count: rowCount } } },
        });
      } catch (e) {
        yield put<SaveInvokePreviewResultAction>({
          type: 'saveInvokePreviewResult',
          payload: {
            invokePreviewResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokePreview.error`),
            },
          },
        });
      }
    },
    *invokeVizPreview(
      {
        payload,
      }: {
        payload: { code: string; column: string };
      },
      { put },
    ) {
      try {
        const { column, code } = payload;
        const cleanedQuery = code.trim().replace(/;$/, '');

        const limitValue = 1000;

        const queryWithLimit = `${cleanedQuery} LIMIT ${limitValue}`;

        const finalQuery = `
        SELECT ${column} AS "group", COUNT(*) AS "count"
        FROM (
          ${queryWithLimit}
        ) nested
        GROUP BY ${column};
      `;
        const rs = yield* sgcall(() => Dataset.testSqlQuery(finalQuery, 1000, 0, [], []));

        yield put<SaveInvokeVizPreviewResultAction>({
          type: 'saveInvokeVizPreviewResult',
          payload: { invokeVizPreviewResult: { success: true, data: rs } },
        });
      } catch (e) {
        yield put<SaveInvokeVizPreviewResultAction>({
          type: 'saveInvokeVizPreviewResult',
          payload: {
            invokeVizPreviewResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeVizPreview.error`),
            },
          },
        });
      }
    },
    *invokeSaveLocally(_, { call, put, select }) {
      try {
        const dataset = yield select((s: ReduxState) => s.dataset.current);
        assertIsDataset(dataset, 'BEEM220723124313');

        const editState = yield* sgselect((s) => s.dataset.editState, 'BEEM220920094518');
        const { sqlQuery, trigger, triggerDetails } = editState.current;

        yield call(dataset.saveLocally, { sqlQuery, trigger, triggerDetails });

        const sqlQueryEtcChanges = yield* sgcall(() => dataset.getSqlQueryEtcChanges(1));
        const { timestamp } = sqlQueryEtcChanges[0];

        yield put<SaveEditStateAction>({
          type: 'saveEditState',
          payload: {
            editState: new DatasetEditState({
              initial: editState.initial,
              current: { timestamp, sqlQuery, trigger, triggerDetails },
              saved: { timestamp, sqlQuery, trigger, triggerDetails },
              deployed: editState.deployed,
            }),
          },
        });

        yield put<SaveInvokeSaveLocallyResultAction>({
          type: 'saveInvokeSaveLocallyResult',
          payload: { invokeSaveLocallyResult: { success: true, data: null } },
        });
      } catch (e) {
        yield put<SaveInvokeSaveLocallyResultAction>({
          type: 'saveInvokeSaveLocallyResult',
          payload: {
            invokeSaveLocallyResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeSaveLocally.error`),
            },
          },
        });
      }
    },
    *invokeDeployRemotely(_, { call, put, select }) {
      try {
        const dataset = yield select((s: ReduxState) => s.dataset.current);
        assertIsDataset(dataset, 'BEEM220723124313');

        const editState = yield* sgselect((s) => s.dataset.editState, 'BEEM220920094519');
        const { sqlQuery, trigger, triggerDetails } = editState.current;
        const oldCode = editState.deployed ? editState.deployed.sqlQuery : null;

        yield call(dataset.deploy, { sqlQuery, trigger, triggerDetails });

        const sqlQueryEtcChanges = yield* sgcall(() => dataset.getSqlQueryEtcChanges(1));
        const { timestamp } = sqlQueryEtcChanges[0];

        yield put<SaveEditStateAction>({
          type: 'saveEditState',
          payload: {
            editState: new DatasetEditState({
              initial: editState.initial,
              current: { timestamp, sqlQuery, trigger, triggerDetails },
              saved: { timestamp, sqlQuery, trigger, triggerDetails },
              deployed: { timestamp, sqlQuery, trigger, triggerDetails },
            }),
          },
        });

        yield put<SaveInvokeDeployRemotelyResultAction>({
          type: 'saveInvokeDeployRemotelyResult',
          payload: {
            invokeDeployRemotelyResult: {
              success: true,
              data: { unchanged: sqlQuery === oldCode },
            },
          },
        });
      } catch (e) {
        yield put<SaveInvokeDeployRemotelyResultAction>({
          type: 'saveInvokeDeployRemotelyResult',
          payload: {
            invokeDeployRemotelyResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeDeployRemotely.error`),
            },
          },
        });
      }
    },
  },
  reducers: {
    resetAll() {
      return { ...initialState };
    },
    saveCurrent(state, { payload }) {
      return { ...state, ...payload };
    },
    saveRowCount(state, { payload }) {
      return { ...state, ...payload };
    },
    savePreviewRowCount(state, { payload }) {
      return { ...state, ...payload };
    },
    saveRecords(state, { payload }) {
      return { ...state, ...payload };
    },
    saveConsolidatedTestResults(state, { payload }) {
      return { ...state, ...payload };
    },
    saveViewState(state, { payload }) {
      return { ...state, ...payload };
    },
    saveEditState(state, { payload }) {
      return { ...state, ...payload };
    },
    saveSqlQuery(state, { payload }) {
      return { ...state, ...payload };
    },
    savePreviewChartConfig(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeUpdateResult(state, { payload }) {
      if (!payload.invokeUpdateResult?.success) {
        return state;
      }

      const newState = {
        ...state,
        current: payload.invokeUpdateResult?.data,
      };
      return newState;
    },
    saveInvokeCreateResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeRenameResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeMoveResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeDeleteResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeRefreshResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokePreviewResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeVizPreviewResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeSaveLocallyResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeDeployRemotelyResult(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default Dataset2Model;
