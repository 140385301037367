import * as React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none">
    <path
      fill="#0067F4"
      d="m17.69 9.512.274.962a1 1 0 0 0 .687-1.237l-.961.275Zm-2.024-1.5-.5.866.5-.866Zm2.859 7.936a1 1 0 0 0-1.732-1l1.732 1Zm-.874-10.21a1 1 0 1 0-1.923.549l1.923-.55ZM13.915 9.55a1 1 0 0 0 .55 1.923l-.55-1.923Zm3.775-.039.626-.78h-.002l-.003-.003a4.873 4.873 0 0 0-.013-.01l-.05-.04a55.703 55.703 0 0 0-.754-.59c-.423-.325-.984-.745-1.328-.943l-1 1.732c.208.12.66.45 1.11.797a51.418 51.418 0 0 1 .726.568l.046.037.012.009a.102.102 0 0 1 .003.002l.627-.779Zm-1.524-2.366a6.444 6.444 0 0 0-8.802 2.358l1.732 1a4.444 4.444 0 0 1 6.07-1.626l1-1.732ZM7.364 9.504a6.444 6.444 0 0 0 2.359 8.802l1-1.732a4.443 4.443 0 0 1-1.627-6.07l-1.732-1Zm2.359 8.802a6.444 6.444 0 0 0 8.802-2.358l-1.732-1a4.443 4.443 0 0 1-6.07 1.626l-1 1.732Zm8.928-9.069-1-3.5-1.923.55 1 3.5 1.923-.55Zm-1.236-.686-3.5 1 .55 1.923 3.5-1-.55-1.923Z"
    />
  </svg>
);
